<template>
    <div class="loginBg">
        <div id="main">
            <div class="container">
                <div class="title wow animated fadeInDown">{{$t('login.register')}}</div>

                <div class="form_box wow animated fadeIn">
                    <div class="inp_label">{{$t('common.userName')}}</div>
                    <input v-model="account" class="inp" type="text" data-index="username" :placeholder="$t('tip.usernameEmpty')" />

                    <div class="inp_label">{{$t('common.pwd')}}</div>
                    <input v-model="password1" class="inp" type="password" data-index="password" :placeholder="$t('tip.commonVer')" />
                    
                    <div class="inp_label">{{$t('login.confirm')}}</div>
                    <input v-model="password2" class="inp" type="password" data-index="password" :placeholder="$t('tip.commonVer')" />

                    <input v-model="code" class="inp" type="text" data-index="password" :placeholder="$t('tip.invite')" />


                    <button @click="register" class="btn login">{{$t('common.register')}}</button>
                    <router-link to="/login" class="btn btn-o">
                    <span class="gradient">{{$t('login.toLogin')}}</span></router-link>
                    <div class="fd">
                        <label for="readme">
                            <input v-model="checked" type="checkbox" name="readme" id="readme" class="checkbox">{{$t('login.agree')}}
                        </label>
                        <router-link class="gradient" to="/agreement">《{{$t('common.agreement')}}》</router-link>
                    </div>
                </div>
                <div class="login_footer wow animated fadeInUp">
                    <p><span>System version {{version}} </span> @ 2022 LLKKZ.NET ALL RIGHTS RESERVED</p>
                </div>
            </div>
            <div class="icon">
                <img src="@/assets/images/official/login/icon.png" alt="">
            </div>
        </div>
    </div>
</template>
<script>
import {Message} from 'element-ui'
import { WOW } from 'wowjs'
import { mapGetters } from 'vuex';
// api
import {register} from '@/api/user'
export default {
    data(){
        return{
            account:'',
            password1:'',
            password2:'',
            code:'',
            checked:false,
        }
    },
    computed:{
        ...mapGetters(['version','isTwitter'])
    },
    mounted(){
        this.$nextTick(()=>{
            this.wow();
        })
    },
    methods:{
        wow(){
            // if( document.querySelectorAll('.wow').length ){
            //     if (! (/msie [6|7|8|9]/i.test(navigator.userAgent))) {
            var wow = new WOW({
                boxClass:     'wow',      // animated element css class (default is wow)
                animateClass: 'animated', // animation css class (default is animated)
                offset:       0,          // distance to the element when triggering the animation (default is 0)
                mobile:       true,       // trigger animations on mobile devices (default is true)
                live:         true       // act on asynchronously loaded content (default is true)
            });
            this.$nextTick(() => {
                wow.init();
            });
            //     };
            // };
        },
        register(){
            // console.log('注册')
            // var emailReg=/\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/
            // /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
            // var phoneReg=/^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/;
            // var phoneReg=/^1[3456789]\d{9}$/;
            // var pwdReg=/[A-Za-z].*[0-9]|[0-9].*[A-Za-z]/
            const reg = /[A-Za-z].*[0-9]|[0-9].*[A-Za-z]/;
            if(!this.account){
                Message({
                    message:this.$t('tip.usernameEmpty')+'！',
                    type: 'error',
                    duration: 3 * 1000
                });
            }else if(!reg.test(this.account) || this.account.length < 6){
                Message({
                    message:this.$t('tip.accountVer')+'！',
                    type: 'error',
                    duration: 3 * 1000
                });
            }else if(!this.password1){
                Message({
                    message:this.$t('tip.pwdEmpty1')+'！',
                    type: 'error',
                    duration: 3 * 1000
                });
            }else if(!reg.test(this.password1) || this.password1.length < 6){
                Message({
                    message:this.$t('tip.pwdVer')+'！',
                    type: 'error',
                    duration: 3 * 1000
                });
            }else if(this.password1!=this.password2){
                Message({
                    message:this.$t('tip.pwd2')+'！',
                    type: 'error',
                    duration: 3 * 1000
                });
            }else if(!this.checked){
                Message({
                    message:this.$t('tip.check')+'！',
                    type: 'error',
                    duration: 3 * 1000
                });
            }else{
                var register_source='0';
                if(this.isTwitter==1){
                    register_source='twitter'
                }else if(this.isTwitter==2){
                    register_source='qrcode'
                }
                register({
                    account:this.account,
                    password:this.password2,
                    code:this.code,
                    register_source
                }).then(res=>{
					this.$message({
						message: this.$t('tip.registerSuc'),
						center: true,
						type: 'success',
						customClass: 'shotMsg',
					});
                    // 如果存在token，则退出登录
                    this.$store.dispatch('user/logout').then(res=>{
						router.push({
						    path:'/login'
						})
					})
                    // this.$router.push({
                    //     path:'/login'
                    // })
                })
            }
        },
    },
    created(){
        if(this.$route.query.code){
            this.code=this.$route.query.code;
        }
    },
}
</script>
<style scoped="scoped" lang="stylus">
	@import '~@/views/login/login.styl';
	@import '~@/assets/style/frames.styl';
</style>